import { convertToKebabCase } from '@/modules/utils'
import { IAnalyticsContext } from '@sennder/senn-node-microfrontend-interfaces'
import { AnalyticsProvider } from '@sennder/shell-utilities'

import { logger } from '@/services/logger/loggers'

const DEFAULT_PREFIX = 'shipper-portal'

const getPageViewEventName = (name: string): string => {
  if (!name) {
    throw Error('[otters-shell - Analytics]: Could not track invalid page name')
  }
  return `${DEFAULT_PREFIX}/view/${convertToKebabCase(name)}-page`
}

export class AppAnalyticsProvider extends AnalyticsProvider {
  // enforce app specific analytics configuration
  constructor(context: IAnalyticsContext) {
    super({
      context,
      logger,
      messageSource: 'shipperPortal',
      dedupePageEvents: true,
      formatEventName: undefined,
      formatPageEventName: getPageViewEventName,
    })
  }

  trackShellEvent(name: string, properties: Record<string, any> = {}) {
    this.trackEvent(`${DEFAULT_PREFIX}/${name}`, properties)
  }
}

export const analytics = new AppAnalyticsProvider({
  module: 'shipperPortal',
  submodule: '',
})
