import { RouteLocationNormalized } from 'vue-router'
import { IModulesConfig } from '@sennder/senn-node-microfrontend-interfaces'
import { OttersStoreData } from '@/store'
import { moduleConfiguration } from '@/modules-configuration'

type VisibilityHandler = {
  handler: (data: OttersStoreData) => boolean
  fallbackRoute: Record<string, any>
}
const handlers: {
  [componentName: string]: {
    handler: (data: OttersStoreData) => boolean
    fallbackRoute?: Record<string, any>
  }
} = {
  foo: {
    handler: (data: OttersStoreData) => {
      return data !== null
    },
    fallbackRoute: { path: '/foo' },
  },
}

export const getVisibilityHandler = (
  componentName: string
): VisibilityHandler | null => {
  if (!handlers[componentName]) {
    return null
  }

  const { handler, fallbackRoute } = handlers[componentName]

  return {
    handler,
    fallbackRoute: fallbackRoute || { path: '/' },
  }
}

export const isModuleVisible = (
  module: IModulesConfig,
  data: OttersStoreData
) => {
  const isEnabledByFeatureFlag =
    !module.featureFlag || data.featureFlags[module.featureFlag]

  const visibilityHandler = getVisibilityHandler(module.component)

  const isEnabledByVisibilityHandler =
    !visibilityHandler || visibilityHandler.handler(data)

  return isEnabledByFeatureFlag && isEnabledByVisibilityHandler
}

export const getFeatureFlagForRoute = (route: RouteLocationNormalized) => {
  const module = moduleConfiguration.find(
    (module: IModulesConfig) => module.name === route.name
  )
  return module?.featureFlag
}
