import { loadModule } from '../loader'
import { ENV } from '@/common/config'

const NAMESPACE = 'ottersauthmfcomponent'
const REMOTE = './ottersauthmfcomponentApp'

// For local development set dev to 'http://localhost:9100/'
const urls = {
  dev: 'https://cdn.dev.cloud.sennder.com/npm/otters-auth-mf-component/',
  uat: 'https://cdn.uat.cloud.sennder.com/npm/otters-auth-mf-component/',
  prod: 'https://cdn.cloud.sennder.com/npm/otters-auth-mf-component/',
}

export const auth = {
  bootstrap: async () => {
    if (auth.mount) return auth.mount
    const { mount } = await loadModule(
      `${urls[ENV as keyof typeof urls]}remoteEntry.js`,
      NAMESPACE,
      REMOTE
    )
    auth.mount = mount
    return mount
  },
  mount: null,
}
