import { getAuth0Instance } from '@sennder/senn-node-auth0-frontend'
import { logger } from '@/services/logger/loggers'
import { clearState } from '@/store/index'
import { anonymizeTracking } from '@/services/tracking'
import { anonymizeUserInLaunchDarkly } from '@/services/launchDarkly'

const logoutWithAuth0 = async () => {
  const auth0Instance = getAuth0Instance()
  try {
    await auth0Instance.logout({ returnTo: window.location.origin })
  } catch (e) {
    console.error('[Otters shell] Cant logout with Auth0 token: ', e)
  }
}

export const logout = async () => {
  const auth0Instance = getAuth0Instance()
  if (!auth0Instance) {
    throw Error('[Otters shell] Auth0 was not initialized')
  }
  const isAuthenticated = await auth0Instance.isAuthenticated()

  if (isAuthenticated) {
    await logoutWithAuth0()
  }
  clearState()
  logger.unsetUserData()
  // We should remove cookie consent here ..

  anonymizeTracking()
  await anonymizeUserInLaunchDarkly()
}
