import DatadogLogger from '@sennder/senn-node-frontend-loggers/lib/datadog/DatadogLogger'
import { IShellLogProvider } from '@sennder/senn-node-microfrontend-interfaces'

import {
  APP_VERSION,
  ENV,
  DATADOG_CLIENT_TOKEN,
  IS_LOCALHOST,
} from '@/common/config'

const DATADOG_DOMAIN = 'datadoghq.eu'
const SERVICE = 'otters-shell'

const consoleLogger: IShellLogProvider = {
  debug: (message: string, ...args: any[]) => {
    console.debug(message, ...args)
  },
  error: (message: string, ...args: any[]) => {
    console.error(message, ...args)
  },
  info: (message: string, ...args: any[]) => {
    console.info(message, ...args)
  },
  warn: (message: string, ...args: any[]) => {
    console.warn(message, ...args)
  },
  setUserData: () => {},
  unsetUserData: () => {},
  setHandlerType: () => {},
}

let datadogLogger: DatadogLogger | undefined = undefined

function getDatadogLogger() {
  if (!datadogLogger) {
    if (!DATADOG_CLIENT_TOKEN) {
      throw new Error('DATADOG_CLIENT_TOKEN not found')
    }
    datadogLogger = new DatadogLogger(
      DATADOG_CLIENT_TOKEN,
      SERVICE,
      DATADOG_DOMAIN,
      ENV,
      APP_VERSION
    )
  }
  return datadogLogger
}

export let loggerInstance = consoleLogger

export function initLogger() {
  if (!IS_LOCALHOST) {
    loggerInstance = getDatadogLogger()
    loggerInstance.setHandlerType('http')
    loggerInstance.debug('Starting log collection', {})
  }
}

export function stopLogger() {
  loggerInstance.debug('Ending log collection', {})
  loggerInstance.setHandlerType('silent')
}

export function identifyUserInLogger(userId: string, userEmail: string) {
  loggerInstance.setUserData(userId, userEmail)
}
