import {
  IMicrofrontendData,
  IOttersSharedData,
  IShipperUser,
  IShipperCompany,
  SharedDataType,
  GetTokenOptions,
  IAction,
  ICommonHeaders,
  OrgType,
} from '@sennder/senn-node-microfrontend-interfaces'
import { getAuth0Header, getAuth0Token } from '@/services/tokenManager'
import { getFeatureFlags } from '@sennder/senn-node-feature-flags-frontend'
import { reactive, watch } from 'vue'
import { logger } from '@/services/logger/loggers'
import { logout } from '@/store/logoutActions'
import errorsHandler from '@/services/errors-handler'
export { loadUserData } from './userDataHelper'
import SPSService from '@/services/spsService'
import { i18n } from '@/services/i18n'
import { getTenant } from '@/plugins/tenants'
import { analytics } from '@/services/analyticsProvider'
import { Optional, Prettify } from '@/types/types'
import { sendErrorInMonitor } from '@/services/monitor'
import { permissionService } from '@/services/permissionService'
import shipperUserService from '@/services/shipperUserService'
import { AUTH0_SUS_AUDIENCE } from '@/common/config'
import startAsyncService from '@/services/retry-service'
import { identifyUserInLaunchDarkly } from '@/services/launchDarkly'

const defaultTokenOptions: GetTokenOptions = {
  usePopup: false,
  throwException: false,
}

const getAuthToken = async (options = defaultTokenOptions) => {
  const { usePopup, throwException, ...restOptions } = options
  return getAuth0Token(restOptions, usePopup, throwException)
}

const getAuthHeader = async (options = defaultTokenOptions) => {
  const { usePopup, throwException, ...restOptions } = options
  return getAuth0Header(restOptions, usePopup, throwException)
}

async function getCommonHeaders(): Promise<ICommonHeaders> {
  if (!AUTH0_SUS_AUDIENCE) {
    throw new Error('AUTH0_SUS_AUDIENCE is not defined')
  }
  return {
    Authorization: await getAuth0Header({
      audience: AUTH0_SUS_AUDIENCE,
    }),
    'X-Org-Type': OrgType.ORGANIZATION,
    'X-Org-Id': 'sennder',
  }
}

export type OttersExtendedData = {
  menuCollapsed: boolean
}

export type OttersStoreData = Prettify<
  Optional<
    IOttersSharedData<OttersExtendedData>,
    'user' | 'company' | 'children'
  >
>

const getEmptyData = (): OttersStoreData => {
  return {
    type: SharedDataType.OTTERS,
    featureFlags: getFeatureFlags() || {},
    language: 'en',
    tenant: getTenant(),
    menuCollapsed: false,
  }
}

const store = reactive({
  state: {
    data: getEmptyData(),
    callbacks: {
      getToken: getAuthHeader,
      getAuthHeader,
      getAuthToken,
      syncParentRouter: () => {},
      segmentTrackEvent: (event, properties) => {
        analytics.trackEvent(event, properties)
      },
      onUnauthorized: async () => {
        return logout()
      },
      onUnhandledError: errorsHandler,
      getCommonHeaders,
      getPermissions: async (actions: IAction[]) =>
        permissionService.getPermissions(actions, await getCommonHeaders()),
    },
    providers: {
      logger,
      notifications: {
        error: (message: string, options = {}) =>
          console.log(
            message,
            options,
            '[error notification] function is not implemented.'
          ),
        success: (message: string, options = {}) =>
          console.log(
            message,
            options,
            '[success notification] function is not implemented.'
          ),
      },
      translations: {
        tc: i18n.global.tc,
        t: i18n.global.t,
      },
      segment: analytics,
      analytics,
      monitoring: {
        sendError: sendErrorInMonitor,
      },
    },
  } satisfies IMicrofrontendData<OttersStoreData>,
})

// watch feature flags changes in senn-node-feature-flags-frontend and update store
watch(
  getFeatureFlags,
  (featureFlags) => {
    store.state.data.featureFlags = featureFlags
  },
  { deep: true }
)

export const getStateData = () => {
  return store.state.data
}

export const getStateUser = () => {
  if (!store.state.data.user) {
    throw new Error('[Store] state.data.user is not initialized')
  }
  return store.state.data.user
}

export const getStateCompany = () => {
  if (!store.state.data.company) {
    throw new Error('[Store] state.data.company is not initialized')
  }
  return store.state.data.company
}

export const getStateProviders = () => {
  return store.state.providers
}

export const getStateCallbacks = () => {
  return store.state.callbacks
}

export const getStateFeatureFlags = () => {
  return store.state.data.featureFlags
}

export const loadState = async () => {
  let userInfo: IShipperUser
  let companyInfo: IShipperCompany | undefined
  let companyChildren: IShipperCompany[] | undefined

  try {
    userInfo = await shipperUserService.getUser()
    userInfo.fullNameWithoutTitle = `${userInfo?.firstName} ${userInfo?.lastName}`
  } catch (error) {
    logger.error('[Shipper User Service]: Data cannot be loaded', { error })
    await logout()
    return false
  }

  try {
    companyInfo = await SPSService.getShipper(userInfo.shipperUuid)
    companyChildren = await SPSService.getShipperChildren(userInfo.shipperUuid)
  } catch (error) {
    logger.error('[SPS Service]: Data cannot be loaded', { error })
    await logout()
    return false
  }

  const menuCollapsedValue = localStorage.getItem('menuCollapsed')
  const menuCollapsed =
    !!menuCollapsedValue && JSON.parse(menuCollapsedValue) === true

  startAsyncService('LaunchDarkly', () => identifyUserInLaunchDarkly(userInfo))

  store.state.data = {
    type: SharedDataType.OTTERS,
    user: userInfo,
    company: companyInfo,
    children: companyChildren,
    language: 'en',
    featureFlags: getStateData().featureFlags,
    menuCollapsed,
    tenant: getTenant(),
  }

  return true
}

export const setStoreLanguage = async (language: string) => {
  store.state.data.language = language
}

export const setMenuCollapsed = (collapsed: boolean) => {
  store.state.data.menuCollapsed = collapsed
  localStorage.setItem('menuCollapsed', `${collapsed}`)
}

export const clearState = () => {
  store.state.data = getEmptyData()
}

export const setStoreFeatureFlags = (
  featureFlags: Record<string, boolean | string>
) => {
  store.state.data.featureFlags = featureFlags
}

export const setStoreData = (data: OttersStoreData) => {
  store.state.data = data
}
