import { createI18n } from 'vue-i18n'
import { App } from 'vue'
import { isLocalEnv } from '@/common/config'

export const languages = [
  { key: 'locale.en', value: 'en' },
  { key: 'locale.de', value: 'de' },
  { key: 'locale.es', value: 'es' },
  { key: 'locale.fr', value: 'fr' },
  { key: 'locale.it', value: 'it' },
  { key: 'locale.pt', value: 'pt' },
  { key: 'locale.lv', value: 'lv' },
  { key: 'locale.pl', value: 'pl' },
  { key: 'locale.ro', value: 'ro' },
  { key: 'locale.nl', value: 'nl' },
]

export const defaultLanguage = 'en'

export const i18n = createI18n({
  locale: defaultLanguage, // set locale
  fallbackLocale: isLocalEnv() ? 'untranslated' : defaultLanguage, // set fallback locale
  globalInjection: true,
  messages: {},
})

export function registerI18nPlugin(app: App) {
  app.use(i18n)
}
