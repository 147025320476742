import { TRANSLATIONS_URL } from '@/common/config'
import { i18n, defaultLanguage } from '@/services/i18n'
import { logger } from '@/services/logger/loggers'
import { setStoreLanguage } from '@/store'
import { TranslationProvider } from '@sennder/shell-utilities'

if (!TRANSLATIONS_URL) {
  throw new Error('TRANSLATIONS_URL is not defined')
}

export const afterTranslationChanged = (
  language: string,
  translations: object
) => {
  i18n.global.setLocaleMessage(language, translations)
  i18n.global.locale = language
  setStoreLanguage(language)
}

export const translationProvider = new TranslationProvider({
  cloudServiceUrl: TRANSLATIONS_URL,
  supported: [
    'en',
    'de',
    'es',
    'fr',
    'it',
    'pt',
    'lv',
    'pl',
    'ro',
    'nl',
    'untranslated',
  ],
  defaultLanguage,
  logger,
  afterTranslationChanged,
  loadLocalTranslation: async (language) =>
    import(
      /* webpackChunkName: "chunk.i18n-[request]" */ `@/languages/${language}.json`
    ),
})
