import { logger } from '@/services/logger/loggers'

const TRIES_TO_RUN = 5
const INTERVAL_BETWEEN_TRIES = 2000
const DEFAULT_ERROR_MESSAGE = 'service is failed after retry with message:'

const sleep = async (interval: number) => {
  await new Promise((resolve) => {
    setTimeout(() => resolve(''), interval)
  })
}

const serviceInitializationFailed = (serviceName: string, error: Error) => {
  const serviceMessage = `${serviceName} ${DEFAULT_ERROR_MESSAGE}`
  logger.error(serviceMessage, {
    error,
  })
}

type AsyncFunction = (...args: any[]) => Promise<void>

export default async function startAsyncService(
  serviceName: string,
  operation: AsyncFunction,
  operationParams = {},
  retries = TRIES_TO_RUN,
  interval = INTERVAL_BETWEEN_TRIES
) {
  if (!Number.isInteger(retries) || retries < 0) {
    throw new Error(
      '[Otters shell - startAsyncService]: parameter retries is not a number'
    )
  }
  try {
    await operation(operationParams)
  } catch (error: any) {
    if (retries === 0) {
      serviceInitializationFailed(serviceName, error)
      return
    }
    await sleep(interval)
    return startAsyncService(
      serviceName,
      operation,
      operationParams,
      retries - 1,
      interval
    )
  }
}
