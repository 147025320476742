import { RouteLocationNormalized, Router } from 'vue-router'
import {
  getFeatureFlagForRoute,
  getVisibilityHandler,
} from '@/modules/visibility-handlers'
import { getStateData } from '@/store'
import { getFirstVisibleModulePath } from '../routes'

const getRouteVisibilityHandler = (route: RouteLocationNormalized) => {
  const componentName = route.meta.npmName
  if (!componentName) {
    return null
  }

  return getVisibilityHandler(componentName)
}

const checkRouteFeatureFlag = (route: RouteLocationNormalized) => {
  const routeFeatureFlag = getFeatureFlagForRoute(route)
  if (!routeFeatureFlag) {
    return true
  }
  return !!getStateData().featureFlags[routeFeatureFlag]
}

export const routeNavigationGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) => {
  const middlewares = to.meta.middlewares

  if (!middlewares || middlewares.length === 0) {
    return true
  }

  for (const middleware of middlewares) {
    const result = await middleware({ to, from })
    if (
      typeof result === 'object' ||
      typeof result === 'string' ||
      result === false
    ) {
      return result
    }
  }

  const featureFlag = checkRouteFeatureFlag(to)
  if (!featureFlag) {
    return getFirstVisibleModulePath()
  }

  const visibilityHandler = getRouteVisibilityHandler(to)
  if (!visibilityHandler) {
    return true
  }

  if (visibilityHandler.handler(getStateData())) {
    return true
  }

  return visibilityHandler.fallbackRoute
}

export const registerMiddlewares = (router: Router) => {
  router.beforeEach(routeNavigationGuard)
}
