import { Tenant } from '@sennder/senn-node-microfrontend-interfaces'

const parseJwt = (token: string): Record<string, any> | null => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch {
    return null
  }
}

export const extractTenantFromToken = (token: string): Tenant | null => {
  const parsedToken = parseJwt(token)
  if (parsedToken) {
    const tenantKey = Object.keys(parsedToken).find((key) =>
      key.includes('tenant')
    )
    if (tenantKey) {
      return (
        (Object.values(Tenant).find(
          (key) => key === parsedToken[tenantKey]
        ) as Tenant) ?? null
      )
    }
  }
  return null
}

let currentTenant: Tenant = Tenant.SENNDER

// This function is needed to initial auth0 connection identification
// It also set initial currentTenant. It will be overwritten from auth0 jwt token
export const parseTenantUrl = (): Tenant => {
  if (location.hostname.includes(Tenant.DEMO)) {
    currentTenant = Tenant.DEMO
  }
  return currentTenant
}

export const getTenant = (): Tenant => currentTenant
export const setTenant = (tenant: Tenant): void => {
  currentTenant = tenant
}

interface ITenantPermission {
  tenants: Array<Tenant>
  featureFlags: Array<string>
}

// OPTIONAL: Define enum tenant permissions
export enum TenantPermissions {
  exampleWithFF = 'exampleWithFF',
  exampleWithoutFF = 'exampleWithoutFF',
}

// OPTIONAL: Define app tenant permissions
const tenantsPermissions: { [key: string]: ITenantPermission } = {
  [TenantPermissions.exampleWithFF]: {
    tenants: [Tenant.DEMO],
    featureFlags: ['example-feature-flag'],
  },
  [TenantPermissions.exampleWithoutFF]: {
    tenants: [Tenant.DEMO],
    featureFlags: [],
  },
}

export const getCurrentTenantPermission = (
  permission: TenantPermissions,
  featureFlags: Record<string, any>
): boolean => {
  const currentPermission = tenantsPermissions[permission]
  if (currentPermission) {
    const tenantAllowed = currentPermission.tenants.includes(getTenant())
    if (tenantAllowed) {
      const hasFeatureFlags = currentPermission.featureFlags.length > 0
      if (hasFeatureFlags) {
        return currentPermission.featureFlags.every((flag) => {
          return featureFlags[flag]
        })
      }
      return true
    }
  }
  return false
}
