import { isNotAuthenticated } from '@/router/middlewares/middlewares'

export default [
  {
    path: '/login',
    name: 'Login',
    meta: {
      middlewares: [isNotAuthenticated],
      analyticsModuleName: 'auth',
    },
    component: () => import('./AuthMicrofrontend.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    meta: {
      middlewares: [isNotAuthenticated],
      analyticsModuleName: 'auth',
    },
    component: () => import('./AuthMicrofrontend.vue'),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    meta: {
      middlewares: [isNotAuthenticated],
      analyticsModuleName: 'auth',
    },
    component: () => import('./AuthMicrofrontend.vue'),
  },
]
