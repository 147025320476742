export enum CookieConsentType {
  ALL = 'ALL',
  ESSENTIALS = 'ESSENTIALS',
}

export class CookieConsentService {
  private localeStorageKey = 'COOKIE_CONSENT'
  static didConsent: any

  get didConsentAll() {
    return localStorage.getItem(this.localeStorageKey) === CookieConsentType.ALL
  }

  get didConsentEssentials() {
    return (
      localStorage.getItem(this.localeStorageKey) ===
      CookieConsentType.ESSENTIALS
    )
  }

  get didConsent() {
    return localStorage.getItem(this.localeStorageKey) !== null
  }

  consentAll() {
    localStorage.setItem(this.localeStorageKey, CookieConsentType.ALL)
  }

  consentOnlyEssentials() {
    localStorage.setItem(this.localeStorageKey, CookieConsentType.ESSENTIALS)
  }
}

export const cookieConsentService = new CookieConsentService()
