import { isLocalEnv } from '@/common/config'
import { logger } from '@/services/logger/loggers'
import { sendErrorInMonitor } from '@/services/monitor'

type ErrorCategory = 'error' | 'info' | 'internal' | 'warning'

export type INotification = {
  message: string
  category: ErrorCategory
}

type IErrorWithNotification = {
  notification: INotification
}

export function executeSafelyWithLogging<T extends (...args: any[]) => any>(
  callback: T,
  callbackName: string
) {
  try {
    callback()
  } catch (error) {
    logger.error(
      `[otters-shell] Error while executing ${callbackName}: ${error}`,
      { error }
    )
  }
}

export default (error: any, message = '') => {
  const notificationMessage =
    (error as IErrorWithNotification)?.notification?.message || null

  if (notificationMessage) {
    console.error(notificationMessage, message)
  }

  if (isLocalEnv()) {
    console.error(error, message)
  } else {
    sendErrorInMonitor(error, {
      message,
    })
  }
}
