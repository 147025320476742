import { HttpClient } from './http-client/HttpClient'
import httpProvider from './http-provider'
import { getAuth0Header } from '@/services/tokenManager'
import { SUS_URL } from '@/common/config'
import { AUTH0_SUS_AUDIENCE } from '@/common/config'
import { IShipperUser } from '@sennder/senn-node-microfrontend-interfaces'

class shipperUserService extends HttpClient {
  public async getUser(): Promise<IShipperUser> {
    return this.get('/api/user')
  }
}

if (!SUS_URL) {
  throw new Error('SUS_URL not found')
}

export default new shipperUserService({
  httpProvider,
  getAuthHeader: async () =>
    await getAuth0Header({
      audience: AUTH0_SUS_AUDIENCE,
    }),
  baseURL: SUS_URL,
})
