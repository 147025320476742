import { getAuth0Instance } from '@sennder/senn-node-auth0-frontend'
import { GetTokenSilentlyOptions } from '@auth0/auth0-spa-js'

export async function getAuth0Token(
  options?: GetTokenSilentlyOptions,
  usePopup = false,
  throwException = false
): Promise<string | null> {
  return getAuth0TokenInternal(options, usePopup, throwException)
}

export async function getAuth0Header(
  options?: GetTokenSilentlyOptions,
  usePopup = false,
  throwException = false
): Promise<string | null> {
  const token = await getAuth0Token(options, usePopup, throwException)

  return token ? `Bearer ${token}` : null
}

async function getAuth0TokenInternal(
  options?: GetTokenSilentlyOptions,
  usePopup = false,
  throwException = false
): Promise<string | null> {
  const auth0Instance = getAuth0Instance()

  const isAuthenticated = await auth0Instance?.isAuthenticated()
  if (isAuthenticated) {
    const token = await auth0Instance.getToken(
      options,
      usePopup,
      throwException
    )
    return token
  }
  return null
}
