import { LDContext, identify } from '@sennder/senn-node-feature-flags-frontend'
import { getTenant } from '@/plugins/tenants'
import { IShipperUser } from '@sennder/senn-node-microfrontend-interfaces'

export const identifyUserInLaunchDarkly = async (user: IShipperUser) => {
  const ldContext: LDContext = {
    kind: 'user',
    key: String(user.id),
    email: user.username,
    firstName: user.firstName,
    lastName: user.lastName,
    country: user.timezone,
    tenant: getTenant(),
  }
  await identify(ldContext)
  console.info(
    '[otters-shell - LaunchDarkly]: User is identified in LaunchDarkly'
  )
}

export const getLaunchDarklyAnonymousContext = (): LDContext => {
  return {
    key: 'user_key',
  }
}

export const anonymizeUserInLaunchDarkly = async () => {
  await identify(getLaunchDarklyAnonymousContext())
  console.info(
    '[otters-shell - LaunchDarkly]: User is anonymized in LaunchDarkly'
  )
}
