import { IShellMonitoringProvider } from '@sennder/senn-node-microfrontend-interfaces'
import {
  APP_VERSION,
  ENV,
  DATADOG_APP_ID,
  DATADOG_CLIENT_TOKEN,
  isUatEnv,
  isProductionEnv,
  IS_LOCALHOST,
} from '@/common/config'
import DatadogMonitor from '@sennder/senn-node-frontend-loggers/lib/datadog/DatadogMonitor'
import { logger } from './logger/loggers'

const SITE = 'datadoghq.eu'
const SERVICE = 'otters-shell'
const MASK_SENSITIVE_DATA = true

function getTracesMatch() {
  if (isProductionEnv()) {
    return 'https://api.cloud.sennder.com'
  }

  if (isUatEnv()) {
    return 'https://api.uat.cloud.sennder.com'
  }

  return 'https://api.dev.cloud.sennder.com'
}

const consoleMonitor: IShellMonitoringProvider = {
  sendError(error, context) {
    console.error(error, context)
  },
  setUser(userId, userEmail) {
    console.log('setUser', userId, userEmail)
  },
}

let monitor = consoleMonitor

let datadogMonitor: DatadogMonitor | undefined = undefined

function getDatadogMonitor() {
  if (!datadogMonitor) {
    if (!DATADOG_CLIENT_TOKEN) {
      throw new Error('DATADOG_CLIENT_TOKEN not found')
    }
    if (!DATADOG_APP_ID) {
      throw new Error('DATADOG_APP_ID not found')
    }
    datadogMonitor = new DatadogMonitor(
      DATADOG_APP_ID,
      DATADOG_CLIENT_TOKEN,
      SERVICE,
      SITE,
      ENV,
      APP_VERSION,
      {
        trackResources: true,
        trackLongTasks: true,
        traceSampleRate: 20,
        trackUserInteractions: true,
        allowedTracingUrls: isProductionEnv()
          ? undefined // Disable prod tracing
          : [{ match: getTracesMatch(), propagatorTypes: ['datadog'] }],
      },
      MASK_SENSITIVE_DATA
    )
  }
  return datadogMonitor
}

export function initMonitor() {
  if (!IS_LOCALHOST) {
    logger.debug('[monitor] Starting Datadog monitor', {})
    monitor = getDatadogMonitor()
  }
}

export function stopMonitor() {
  monitor = consoleMonitor
  logger.warn('[monitor] Stopping Datadog monitor is not supported', {})
}

export function sendErrorInMonitor(error: Error, context?: object) {
  monitor.sendError(error, context)
}

export function identifyUserInMonitor(userId: string, userEmail: string) {
  monitor.setUser(userId, userEmail)
}
